<template>
  <v-content class="point" style="background-color: #f9f9f9;">
    <v-tabs
      grow
      fixed
      v-model="tabs"
      class="px-4"
      background-color="transparent"
      v-bind:color="maincolor"
    >
      <v-tab href="#status" class="caption">ステータス</v-tab>
      <v-tab href="#user" class="caption">ユーザー情報</v-tab>
    </v-tabs>

    <v-tabs-items class="" v-model="tabs" style="background-color: #f9f9f9;">
      <!--ステータスタブ -->
      <v-tab-item class="px-4 pt-5" value="status" style="min-height: 100%;">
        <div class="text-center">
          <!-- NEW_DEV-1714 cyber start -->
          <v-chip small class="maincolor-bg">ご利用可能ポイント</v-chip>
          <!-- NEW_DEV-1714 cyber end -->
          <!-- NEW_DEV-1545 cyber start -->
          <p class="my-2 fontsize11 maincolor" v-if="point.last_points_earned_type && point.last_points_earned > 0">
          <!-- NEW_DEV-1545 cyber end -->
            {{point.last_points_earned_type}}
            <!-- NEW_DEV-1682,1684 cyber start -->
            <span class="subtitle-2 font-weight-bold">{{point.last_points_earned}}</span> point
            <span v-if="point.last_points_earned_type == '初回ダウンロード特典'">プレゼント</span>
            <!-- NEW_DEV-1682,1684 cyber end -->
          </p>
          <p class="my-2 fontsize11 maincolor" v-else>
          </p>
        </div>

        <div class="text-center my-12" :class="{ marginadd: !point.rank_detail }">
          <span class="bigpoint">
            <AnimatedNumber :value="num"></AnimatedNumber> </span><br />
          <span class="xsmallpoint">point</span>
        </div>

        <!-- NEW_DEV-1685 cyber end -->
        <div class="text-center my-4" v-if="point.expiration_date=='----年--月--日'"></div>
        <div class="text-center my-4" v-else>
          <v-divider></v-divider>
          <p
            class="fontsize11 mb-0 py-1 font-weight-bold"
            style="color: #9d9d9d;"
          >
            ポイント有効期限：{{point.expiration_date}}
          </p>
          <v-divider></v-divider>
        </div>
        <!-- NEW_DEV-1685 cyber end -->
        
        <!-- ランク表示 -->
        <RankView
          :item="point"
          v-if="point.rank_detail"
          ref="rankView"
          @openDialog="showRankDialog"
        ></RankView>
      </v-tab-item>

      <!-- ユーザー情報タブ -->
      <v-tab-item value="user" class="">
        <!-- NEW_DEV-1701 cyber start -->
        <UserInfo :history="history" :init="init" ref="userInfo"></UserInfo>
        <!-- NEW_DEV-1701 cyber end -->
      </v-tab-item>


    </v-tabs-items>

    <!-- ランクについてダイアログ -->
    <RankDialog :item="point" :init="init" ref="rankDialog"></RankDialog>

    <!-- ランクアップダイアログ -->
    <RankUpDialog :item="rankupinfo" :init="init" ref="rankUpDialog"></RankUpDialog>
  </v-content>
</template>

<script>
import UserInfo from "@/views/point/UserInfo.vue";
import RankView from "@/views/point/RankView.vue";
import RankDialog from "@/views/point/RankDialog.vue";
import RankUpDialog from "@/views/point/RankUpDialog.vue";
import AnimatedNumber from "@/components/AnimatedNumber.vue";
export default {
  components: {
    UserInfo,
    RankView,
    RankDialog,
    RankUpDialog,
    AnimatedNumber,
  },
  data: () => ({
    init: [],
    isExpand: [0],
    maincolor: null,
    tabs: "model",
    num: 0,
    point: null,
    history: null,
    rankupinfo: null,
  }),
  async created () {
    this.init = this.storageGet("*")
    this.maincolor = this.init.color_code
    //NEW_DEV-1545 cyber start
    var preview_flg = this.getUrlGetParam('preview')
    try {
        //ポイント情報取得AP & ポイントランク情報API RP
        var req = {params: {
          'sid': this.init.sid,
          'key': this.init.key,
          'device_id': this.init.device_id,
          'device_uid': this.init.device_uid,
          'os': this.init.os,
          'app_version': this.init.app_version,
          'preview':preview_flg,
        }}

        var res = await this.apiCall('/point', req);
        if (!res) return false;
        this.$set(this, 'point', res);

        //ポイントランク情報API ランクアップしているかどうかを確認する
        if(this.point.rank != null){ //ランク機能を利用しているかどうか判別
          //最大ランクに到達しているかどうか
          if(this.point.rank == this.point.rank_detail.length){
            this.point['rankmax_flg'] = 1
          }else{
            this.point['rankmax_flg'] = 0
          }
          
          //ポイント情報取得API
          var rankupReq = {
            'sid': this.init.sid,
            'key': this.init.key,
            'device_id': this.init.device_id,
            'device_uid': this.init.device_uid,
            'os': this.init.os,
            'app_version': this.init.app_version,
            'preview':preview_flg,
          }
          //NEW_DEV-1545 cyber end
          var rankupRes = await this.apiCall('/point/get-rank', rankupReq, 'post');
          if (!rankupRes) return false;
          this.$set(this, 'rankupinfo', rankupRes);

          if(this.rankupinfo.rankuped == 1){ //ランクアップしていた場合、ランクアップダイアログを表示
            this.$refs.rankUpDialog.open()
          }
        }

        //ポイント履歴取得API
        var res_history = await this.apiCall('/point/history', req);
        if (!res_history) return false;
        this.$set(this, 'history', res_history);

    } catch(e) {
        this.loading = false
        this.callDialog('接続エラー', '一時的なエラーです。ネットワークの状態が不安定です。再度お試しください', 3);
    }
  },
  methods: {
    showRankDialog() {
      this.$refs.rankDialog.open();
    },
  },
  //NEW_DEV-1701 cyber start
  watch: {
    tabs(val){
      this.$refs.userInfo.setTabName(val);
    }
  },
  //NEW_DEV-1701 cyber end
  mounted() {
    this.interval = setInterval(() => {
      this.num = this.point.point;
    }, 1500);
  },
};
</script>

<style scoped>
.bigpoint {
  font-size: 80px;
  font-weight: bold;
  font-family: "Oswald", sans-serif !important;
  color: #333333;
  line-height: 1;
}
.xsmallpoint {
  font-size: 24px;
  font-weight: bold;
  font-family: "Oswald", sans-serif !important;
  color: #333333;
}
.marginadd {
  margin: 130px 0 !important;
}
</style>
<style>
.v-application--wrap {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
</style>
